var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profile"}},[_c('Banner',{attrs:{"bgUserProfile":_vm.bgUserProfile,"id":_vm.userID,"editMode":true},on:{"change-bg-file":_vm.changeBgFile}}),_c('v-row',{staticClass:"d-flex flex-row-reverse mt-2"},[_c('v-btn',{staticClass:"mt-2",class:_vm.$style.btnSave,on:{"click":function($event){return _vm.saveBtn()}}},[_vm._v("Save")])],1),_c('v-form',{staticClass:"d-flex flex-md-row flex-column justify-space-around px-md-16 px-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
            ? _vm.$style.secondaryTitle
            : _vm.$style.secondaryTitleMobile},[_vm._v(" About me ")]),_c('div',[_c('v-textarea',{class:_vm.$style.inputText,attrs:{"label":"Description","dark":"","rules":_vm.descriptionRules,"counter":170,"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.$style.inputText,attrs:{"label":"Birthday date","prepend-inner-icon":"mdi-calendar","readonly":"","dark":"","outlined":""},model:{value:(_vm.birthdayDate),callback:function ($$v) {_vm.birthdayDate=$$v},expression:"birthdayDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"amber accent-4","active-picker":_vm.activePicker,"max":new Date(new Date().setFullYear(new Date().getFullYear() - 13))
                .toISOString()
                .substr(0, 10)},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDate},model:{value:(_vm.birthdayDate),callback:function ($$v) {_vm.birthdayDate=$$v},expression:"birthdayDate"}})],1),_c('v-autocomplete',{class:_vm.$style.inputText,attrs:{"label":"Country","dark":"","items":_vm.countryList,"outlined":"","required":"","item-color":"amber accent-4"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('div',[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Languages ")]),_c('v-autocomplete',{class:_vm.$style.inputText,attrs:{"label":"Language","dark":"","items":_vm.languageList,"outlined":"","item-color":"amber accent-4"},on:{"change":_vm.addLanguage},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.userLanguages),function(lang){return _c('div',{key:lang,staticClass:"mr-2 mb-2"},[_c('v-chip',{class:_vm.$style.chip,attrs:{"close":"","color":_vm.$style.colorCardBg,"text-color":_vm.$style.colorFontPrimary},on:{"click:close":function($event){return _vm.deleteLanguage(lang)}}},[_vm._v(" "+_vm._s(lang)+" ")])],1)}),0)],1)]),_c('v-col',[_c('div',[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Platforms ")]),_c('div',{staticClass:"d-flex flex-column justify-space-between mb-md-0 mb-5"},[_c('v-select',{class:_vm.$style.inputText,attrs:{"label":"Platform","dark":"","item-color":"amber accent-4","items":_vm.platformList,"outlined":""},model:{value:(_vm.plaform),callback:function ($$v) {_vm.plaform=$$v},expression:"plaform"}}),_c('div',{staticClass:"d-flex flex-column flex-md-row"},[_c('v-text-field',{staticClass:"mr-md-5",class:_vm.$style.inputText,attrs:{"label":"Platform username","dark":"","outlined":""},model:{value:(_vm.platformUsername),callback:function ($$v) {_vm.platformUsername=$$v},expression:"platformUsername"}}),_c('v-btn',{staticClass:"py-7",class:_vm.$style.btn,on:{"click":_vm.addPlatform}},[_vm._v("Add")])],1)],1),_c('EditImgGrid',{attrs:{"imgList":_vm.userPlatforms,"imgHeight":"80","imgWidth":"80"},on:{"delete-img":_vm.deletePlatform}})],1),_c('div',[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Social media ")]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between"},[_c('div',{staticClass:"d-flex align-center mb-5 mr-md-5"},[_c('v-img',{attrs:{"src":require('../assets/profile/twitter.png'),"max-height":30,"max-width":30,"contain":""}}),_c('v-text-field',{staticClass:"ml-2",class:_vm.$style.inputText,attrs:{"label":"Twitter ID","hide-details":"","dark":"","outlined":""},model:{value:(_vm.twitterId),callback:function ($$v) {_vm.twitterId=$$v},expression:"twitterId"}})],1),_c('div',{staticClass:"d-flex align-center mb-5"},[_c('v-img',{attrs:{"src":require('../assets/profile/instagram.png'),"max-height":30,"max-width":30,"contain":""}}),_c('v-text-field',{staticClass:"ml-2",class:_vm.$style.inputText,attrs:{"label":"Instagram ID","hide-details":"","dark":"","outlined":""},model:{value:(_vm.instagramId),callback:function ($$v) {_vm.instagramId=$$v},expression:"instagramId"}})],1)]),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between"},[_c('div',{staticClass:"d-flex align-center mb-md-0 mb-5 mr-md-5"},[_c('v-img',{attrs:{"src":require('../assets/profile/discord.png'),"max-height":30,"max-width":30,"contain":""}}),_c('v-text-field',{staticClass:"ml-2",class:_vm.$style.inputText,attrs:{"label":"Discord ID","hide-details":"","dark":"","outlined":""},model:{value:(_vm.discordId),callback:function ($$v) {_vm.discordId=$$v},expression:"discordId"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":require('../assets/profile/twitch.png'),"max-height":30,"max-width":30,"contain":""}}),_c('v-text-field',{staticClass:"ml-2",class:_vm.$style.inputText,attrs:{"label":"Twitch ID","hide-details":"","dark":"","outlined":""},model:{value:(_vm.twitchId),callback:function ($$v) {_vm.twitchId=$$v},expression:"twitchId"}})],1)])])]),_c('v-col',[_c('div',[_c('h3',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$style.secondaryTitle
              : _vm.$style.secondaryTitleMobile},[_vm._v(" Games ")]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-autocomplete',{class:_vm.$style.inputText,attrs:{"label":"Game","dark":"","items":_vm.gameList,"outlined":"","item-color":"amber accent-4"},on:{"change":_vm.addGame},model:{value:(_vm.game),callback:function ($$v) {_vm.game=$$v},expression:"game"}})],1),_c('EditImgGrid',{attrs:{"imgList":_vm.userGames,"imgHeight":"160","imgWidth":"120"},on:{"delete-img":_vm.deleteGame}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }