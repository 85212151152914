import { render, staticRenderFns } from "./EditImgGrid.vue?vue&type=template&id=1a7955d2&"
import script from "./EditImgGrid.vue?vue&type=script&lang=js&"
export * from "./EditImgGrid.vue?vue&type=script&lang=js&"
import style0 from "./EditImgGrid.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBadge,VBtn,VCard,VCol,VIcon,VImg})
